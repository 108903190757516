@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,500,600,700,800,900');

body {
    margin: 0;
}

.text-colorful {
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    fill: transparent !important;
    background-image: linear-gradient(135deg, #818ae6 0%, #e2627c 100%);
}

.main-header {
    background: #141414 !important;
    box-shadow: none !important;
}

.main-menu {
    background: #141414 !important;
}

.main-menu .ant-menu-item {
    color: #fff;
}

.main-menu .ant-menu-item:hover {
    color: rgba(255, 255, 255, 0.85) !important;
}

.main-menu .ant-menu-item-selected:hover {
    color: #31C6FF !important;
}

.content-bg-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 55rem;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
}

.content-bg-container.dark-theme {
    border: 2px solid #212121;
}

@keyframes moveInCircle {
    from {
        transform: rotate(5deg) translateX(25px) rotate(-5deg);
    }

    to {
        transform: rotate(360deg) translateX(25px) rotate(-360deg);
    }
}

@keyframes moveInCircleReversed {
    from {
        transform: rotate(360deg) translateX(25px) rotate(-360deg);
    }

    to {
        transform: rotate(5deg) translateX(25px) rotate(-5deg);
    }
}

.home-tag {
    z-index: 2;
    background-image: linear-gradient(135deg, #818ae6 0%, #e2627c 100%);
    border-radius: 18px;
    font-weight: bold;
    font-size: clamp(12px, 2vw, 20px);
    color: #21123dc9;
    border-style: none;
    padding: 0 4px 5px 0;
}

.emoji-tag {
    background-image: linear-gradient(135deg, #e2627c 0%, #61B5FD 100%);
}

.animation-25 {
    animation: moveInCircle 25s linear infinite;
}

.animation-28 {
    animation: moveInCircle 28s linear infinite;
}

.animation-32 {
    animation: moveInCircle 32s linear infinite;
}

.animation-36 {
    animation: moveInCircle 36s linear infinite;
}

.animation-reversed-25 {
    animation: moveInCircleReversed 25s linear infinite;
}

.animation-reversed-30 {
    animation: moveInCircleReversed 30s linear infinite;
}

.animation-reversed-36 {
    animation: moveInCircleReversed 36s linear infinite;
}

.landing-button:hover {
    background-color: #7ebae8 !important;
}

.landing-button:active {
    background-color: #467dab !important;
}

.chat-container::-webkit-scrollbar {
    width: 10px; /* Mostly for vertical scrollbars */
    height: 10px; /* Mostly for horizontal scrollbars */
}

.chat-container::-webkit-scrollbar-thumb { /* Foreground */
    background: #434C59;
    border-radius: 10px;
}

.chat-container::-webkit-scrollbar-track { /* Background */
    background: #282F36;
    border-radius: 10px;
}

.chat-message {
    position: relative;
}

.chat-message .rce-mbox {
    background: #282f36;
}

.chat-message .rce-mbox-left-notch {
    fill: #282f36;
}

.chat-message .rce-mbox-text {
    color: #DEDFE0;
    font-size: 16px;
}

.chat-message .rce-mbox-time {
    color: #6A7987;
}

.chat-message .rce-mbox-photo--img img {
    height: 300px;
}

.chat-message .rce-mbox-title {
    color: #818AF6;
    font-size: 16px;
    font-weight: bold;
}

.chat-message .rce-avatar-container.default {
    height: 45px;
    width: 45px;
    border-radius: 45px;
}

.chat-message .rce-mbox-photo--img {
    max-height: none;
}

.chat-message .rce-mbox-photo--img img {
    width: 300px;
    height: 450px;
}

.chat-message .rce-mbox-photo .rce-mbox-text {
    margin: 0 0 0 6px;
}

.user-message .rce-mbox {
    background: #818AF6;
}

.user-message .rce-mbox-right-notch {
    fill: #818AF6;
}

.user-message .rce-mbox-text {
    color: #ffffff;
}

.user-message .rce-mbox-time {
    color: #ffffff;
}

.user-message .rce-mbox-title {
    color: #f6ebf7;
}

.sys-message .rce-smsg {
    background: #61B5FD;
    color: #ffffff;
}

.typing-spinner > div {
    width: 10px !important;
    height: 10px !important;
    margin-right: 5px;
}

.chat-select .rce-citem {
    background: #282f36;
    color: #F6EBE7;
}

.chat-select .rce-citem-body--bottom-title {
    color: #6A7987;
}

.chat-select .rce-citem-body--top-time {
    color: #6A7987;
}

.chat-select .rce-citem-body {
    border-color: rgba(42, 82, 120, 0.7);
    border-width: 0.1px;
}

.selected-chat .rce-citem {
    background: #61B5FD;
    color: #ffffff;
}

.chat-select .rce-citem:hover {
    background: rgba(97,181,253, 0.5);
    color: #ffffff;
}

.selected-chat .rce-citem-body--bottom-title, .chat-select:hover .rce-citem-body--bottom-title {
    color: #ffffff;
}

.selected-chat .rce-citem-body--top-time, .chat-select:hover .rce-citem-body--top-time {
    color: #ffffff;
}

.chat-card {
    transition: transform 0.2s, box-shadow 0.2s, border-color 0.2s, opacity 0.2s;
}

.chat-card:hover {
    transform: translateY(-5px);
}

.chat-card:hover {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),0 3px 6px 0 rgba(0, 0, 0, 0.12),0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.chat-card:active {
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.16),0 6px 12px 0 rgba(0, 0, 0, 0.12),0 10px 24px 4px rgba(0, 0, 0, 0.09);
}

.chat-card.dark-theme:hover {
    box-shadow: 0 5px 10px 2px rgba(97, 181, 253, 0.7);
}

.chat-card.dark-theme:active {
    box-shadow: 0 10px 20px 2px rgba(97, 181, 253, 0.7);
}

.persona-question {
    background-color: rgb(43, 44, 45);
}

.persona-question:hover {
    cursor: pointer;
    background-color: rgb(60, 63, 63);
}

.google-login-button, .pay-pal-button {
    background: #ffffff;
    color: #000000;
}

.discord-login-button {
    background: #7289D9;
}

.discord-login-button:hover {
    background: #9eb0e6 !important;
}

.discord-login-button:active {
    background: #5667b3 !important;
}

.twitter-login-button {
    background: #00ACED;
}

.twitter-login-button:hover {
    background: #28c9fa !important;
}

.twitter-login-button:active {
    background: #008bc7 !important;
}

.reddit-login-button {
    background: #FF4500;
}

.reddit-login-button:hover {
    background: #ff6929 !important;
}

.reddit-login-button:active {
    background: #d93300 !important;
}
